import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Logo from "./logo-large"

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  if (typeof window !== `undefined`) {
    const body = document.body
    if (menuOpen) {
      setTimeout(() => {
        body.style.position = "fixed"
        body.style.overflow = "hidden"
      }, 400)
    } else {
      body.style.position = "relative"
      body.style.overflow = "scroll-y"
    }
  }
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const body = document.body

      return () => {
        body.style.position = "relative"
        body.style.overflow = "scroll-y"
      }
    }
  }, [])

  return (
    <>
      {/* Mobile Menu  */}

      <div
        role="navigation"
        className={`mobile-menu`}
        data-sal="fade"
        data-sal-duration="1000"
      >
        <div className="menu-logo-mobile"></div>
        <div id="menuToggle">
          <input
            type="checkbox"
            checked={menuOpen}
            onClick={() => setMenuOpen(!menuOpen)}
          />
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo-small">
          <Link to="/">
            <Logo className="logo-small" />
          </Link>
        </div>
      </div>
      {/* {menuOpen && ( */}
      <nav id="menu" className={menuOpen ? "menu-open" : null}>
        {/* {showHome? <HomeLink /> : <div></div>} */}
        <li>
          <Link to="/music/" activeClassName="active" partiallyActive={true}>
            Music
          </Link>
        </li>
        <li>
          <Link to="/video/" activeClassName="active" partiallyActive={true}>
            Video
          </Link>
        </li>
        <li>
          <Link to="/projects/" activeClassName="active" partiallyActive={true}>
            Projects
          </Link>
        </li>

        <li>
          <Link to="/live/" activeClassName="active" partiallyActive={true}>
            Live
          </Link>
        </li>
        <li>
          <Link to="/about/" activeClassName="active" partiallyActive={true}>
            About
          </Link>
        </li>

        <li>
          <Link to="/contact/" activeClassName="active" partiallyActive={true}>
            Contact
          </Link>
        </li>
      </nav>
    </>
  )
}

export default MobileMenu
